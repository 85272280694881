.nb-icon {
    width: 140px;
    padding-top: 12px;
}

.nb-profile-svg {
    height: 9px;
    width: 30px;
}

.nb-profile-icon-link-container {
    display: flex;
    align-items: center;
}